@font-face {
  font-family: 'Circular Std';
  src: local('Circular Std'),
    url('../assets/font/Circular Std Book.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Circular Std', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
